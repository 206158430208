import { React, useState, useEffect, useRef } from 'react'
import Projects from '../../api/project'
import ProjectSingle from '../ProjectSingle/ProjectSingle';
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';

const ProjectSection = (props) => {

    const [open, setOpen] = useState(false);

    function handleClose() {
        setOpen(false);
    }

    const [state, setState] = useState({
    })

    const handleClickOpen = (item) => {
        setOpen(true);
        setState(item)
    }


    // Isotope filter activation

    const containerRef = useRef(null);
    const iso = useRef(null);
    const [activeFilter, setActiveFilter] = useState('*');

    useEffect(() => {
        imagesLoaded(containerRef.current, function () {
            iso.current = new Isotope(containerRef.current, {
                itemSelector: '.filter-item',
                layoutMode: 'fitRows',
            });
        });
        return () => {
            iso.current.destroy();
        };
    }, []);

    const handleFilterClick = (filterValue) => {
        setActiveFilter(filterValue);
        iso.current.arrange({ filter: filterValue });
      };

    return (


        <section className="portfolio-section gallery-filters" id="Portfolio">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            <h2>Portfolio</h2>
                            <h3>My Latest Works</h3>
                        </div>
                    </div>
                </div>
                <div className="portfolio-category">
                    <ul>
                        <li>
                            <button onClick={() => handleFilterClick('*')} className={activeFilter === '*' ? 'active' : ''}>All</button>
                        </li>
                        <li>
                            <button onClick={() => handleFilterClick('.website')} className={activeFilter === '.website' ? 'active' : ''}>Website</button>
                        </li>
                        <li>
                            <button onClick={() => handleFilterClick('.app')} className={activeFilter === '.app' ? 'active' : ''}>Mobile App</button>
                        </li>
                        <li>
                            <button onClick={() => handleFilterClick('.graphics')} className={activeFilter === '.graphics' ? 'active' : ''}>Graphics</button>
                        </li>
                    </ul>
                </div>
                <div className="portfolio-wrap">
                    <div className="row gallery-container" ref={containerRef}>
                        {Projects.slice(0, 4).map((project, pro) => (
                            <div className={`filter-item col-lg-6 col-md-6 col-12 grid-item  ${project.filterName}`} key={pro}>
                                <div className="portfolio_card">
                                    <div className="image">
                                        <div onClick={() => handleClickOpen(project)}><img src={project.pImg} alt="" /></div>
                                        <span> {project.subTitle}</span>
                                    </div>
                                    <h2 onClick={() => handleClickOpen(project)}>{project.title}</h2>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </div>
            <ProjectSingle open={open} onClose={handleClose} title={state.title} pImg={state.ps1img} psub1img1={state.psub1img1} psub1img2={state.psub1img2} />
        </section>

    );
}

export default ProjectSection;