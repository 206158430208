import pimg1 from '../images/project/img-1.jpg'
import pimg2 from '../images/project/img-2.jpg'
import pimg3 from '../images/project/img-3.jpg'
import pimg4 from '../images/project/img-4.jpg'

import ps1img1 from '../images/project-single/img-1.jpg'
import ps1img2 from '../images/project-single/img-2.jpg'
import ps1img3 from '../images/project-single/img-3.jpg'
import ps1img4 from '../images/project-single/img-4.jpg'

import psub1img1 from '../images/project-single/p1.jpg'
import psub1img2 from '../images/project-single/p2.jpg'


const Projects = [
   {
      Id: '1',
      pImg: pimg1,
      ps1img: ps1img1,
      psub1img1: psub1img1,
      psub1img2: psub1img2,
      title: 'Arrangement Of Leaf',
      subTitle: 'Architecture',
      filterName: 'all app',
      description: 'Sagittis volutpat commodo suspendisse sed in lorem felis eget massa. Risus magna suspendisse sedleo matt bortr malesuada.'
   },
   {
      Id: '2',
      pImg: pimg2,
      ps1img: ps1img2,
      psub1img1: psub1img1,
      psub1img2: psub1img2,
      title: 'Creative Graphics',
      subTitle: 'Web Design',
      filterName: 'all graphics',
      description: 'Sagittis volutpat commodo suspendisse sed in lorem felis eget massa. Risus magna suspendisse sedleo matt bortr malesuada.'
   },
   {
      Id: '3',
      pImg: pimg3,
      ps1img: ps1img3,
      psub1img1: psub1img1,
      psub1img2: psub1img2,
      title: 'Image Multiple Effect',
      subTitle: 'Website / Creative',
      filterName: 'all website app',
      description: 'Sagittis volutpat commodo suspendisse sed in lorem felis eget massa. Risus magna suspendisse sedleo matt bortr malesuada.'
   },
   {
      Id: '4',
      pImg: pimg4,
      ps1img: ps1img4,
      psub1img1: psub1img1,
      psub1img2: psub1img2,
      filterName: 'all graphics app',
      title: 'Decoration Of Tree',
      subTitle: 'Web Design',
   }

]

export default Projects;