import React, { useState } from 'react';
import blogs from '../../api/blogs'
import BlogSingle from "../BlogSingle";


const BlogSection = (props) => {

    const [open, setOpen] = React.useState(false);

    function handleClose() {
        setOpen(false);
    }

    const [state, setState] = useState({
    })

    const handleClickOpen = (item) => {
        setOpen(true);
        setState(item)
    }

    return (

        <section className="blog-section" id="Blog">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            <h2>Blog</h2>
                            <h3>Latest News</h3>
                        </div>
                    </div>
                </div>
                <div className="blog-wrap">
                    {blogs.slice(0, 3).map((blog, bl) => (
                        <div className="item" key={bl}>
                            <div className="image">
                                <img src={blog.screens} alt="" />
                            </div>
                            <div className="content">
                                <ul className="date">
                                    <li>{blog.create_at}</li>
                                    <li>{blog.author}</li>
                                </ul>
                                <h2 onClick={() => handleClickOpen(blog)}>{blog.title}</h2>
                                <h4 onClick={() => handleClickOpen(blog)}>Read More</h4>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <BlogSingle open={open} onClose={handleClose} title={state.title} bImg={state.bSingle} create_at={state.create_at} author={state.author} comment={state.comment} />
        </section>
    );
}

export default BlogSection;